<template>
    <div class="home">
        <el-container>
            <el-aside
                width="200px"
                class="aside"
            >
                <left-menu></left-menu>
            </el-aside>
            <el-container class="main">
                <el-header height="64px">
                    <common-top></common-top>
                </el-header>
                <el-main style="padding:0;">
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import leftMenu from "@/components/leftMenu.vue";
import commonTop from "@/components/commonTop.vue";
import { getUserPerList } from "@/request/api";

export default {
  name: "Home",
  created() {
    this.getUserPermissionsList();
  },
  methods: {
    // 获取角色权限
    getUserPermissionsList() {
      getUserPerList({}).then(res => {
        if (res.status == 200) {
          localStorage.setItem("userPermissionsList", JSON.stringify(res.data));
        }
      });
    }
  },
  components: {
    leftMenu,
    commonTop
  }
};
</script>

<style lang="stylus" scoped>
.aside {
    position: fixed;
    height: 100vh;
    left: 0;
    overflow: auto;
}

.main {
    margin-left: 200px;
}

.el-header {
    border-bottom: 1px solid #f2f2f2;
}
</style>


