<template>
  <div class="menuList">
    <h1>网络货运支付管理系统</h1>
    <el-menu
      :default-active="defauleActive"
      class="el-menu-vertical-demo"
      background-color="#2B303C"
      text-color="#fff"
      @select="handleSelect"
    >
      <template v-for="item in menuList">
        <el-submenu
          v-if="item.childMenu && item.childMenu.length > 0"
          :index="item.rtId"
          :key="item.rtId"
        >
          <template slot="title">
            {{ item.menuName }}
          </template>
          <el-menu-item
            v-for="items in item.childMenu"
            :key="items.rtId"
            :index="items.rtId"
          >
            {{ items.menuName }}
          </el-menu-item>
        </el-submenu>
        <el-menu-item v-else :key="item.rtId" :index="item.rtId">
          <span slot="title">{{ item.menuName }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { getUserMenu } from '@/request/api'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      menuList: [],
      defauleActive: null,
      menuPathList: [
        { id: '21062814404473032322372010000', path: '/platManage' }, // 平台管理
        { id: '21062814404473632322372010000', path: '/openAccountManage' }, // 开户管理
        { id: '21062814404473632322372010001', path: '/accountManage' }, // 账户管理
        { id: '21062814404473732322372010000', path: '/transactionManage' }, // 交易管理
        { id: '21062814404473732322372000000', path: '/listStatementDetail' }, // 明细查询
        { id: '21062814404590632322372010010', path: '/bank' }, // 支持银行管理
        { id: '21062814404590632322372010004', path: '/employeeManage' }, // 员工管理
        { id: '21062814404590732322372010000', path: '/permissionsManage' }, // 权限管理
        { id: '21062814404590732322372010001', path: '/opLog' }, // 操作日志
      ],
    }
  },
  computed: {
    ...mapState(['breadList']),
  },
  created() {
    this.getMenuList()
  },
  watch: {
    breadList(val) {
      if (val.length > 0) {
        this.getDefaultActive(val)
      }
    },
  },
  methods: {
    getDefaultActive(list) {
      for (let i = 0; i < this.menuPathList.length; i++) {
        if (list.length > 1 && list[1].path == this.menuPathList[i].path) {
          this.defauleActive = this.menuPathList[i].id
          return
        }
        if (list[0].path == this.menuPathList[i].path) {
          this.defauleActive = this.menuPathList[i].id
          return
        }
      }
    },
    getMenuList() {
      getUserMenu({}).then((res) => {
        if (res.status == 200) {
          this.menuList = res.data
        }
      })
    },
    handleSelect(a) {
      let path = ''
      for (let i = 0; i < this.menuPathList.length; i++) {
        if (a == this.menuPathList[i].id) {
          path = this.menuPathList[i].path
        }
      }
      if (this.$route.path == path) return
      this.$router.push(path)
    },
  },
}
</script>

<style lang="stylus" scoped>
.menuList {
    position: fixed;
    width: 200px;
    min-width: 200px;
    height: 100vh;
    left: 0;
    overflow: auto;
    background-color: #2B303C !important;
}

h1 {
    height: 64px;
    line-height: 64px;
    color: #fff;
    text-align: center;
}

.is-active {
    color: #ffffff !important;
    background-color: #4F7DF9 !important;
}

.el-submenu, .el-menu-item {
    width: 200px !important;
}

.el-menu-item span, .menuList .el-submenu >>> .el-submenu__title {
    padding-left: 15px !important;
}

.menuList .el-submenu >>> .el-submenu__title {
    padding-left: 35px !important;
}

.menuList .el-submenu >>> .el-menu--inline .el-menu-item {
    padding-left: 60px !important;
}
</style>
