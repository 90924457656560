<template>
  <div class="navTop">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item v-for="item in breadList" :key="item.path">{{
        item.name
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="navTop-right">
      <el-dropdown @command="handleCommand">
        <div>
          <el-avatar size="medium" :src="circleUrl"></el-avatar>
          <span class="userTxt">{{ userName }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="modify">修改密码</el-dropdown-item>
          <el-dropdown-item
            command="loginOut"
            v-loading.fullscreen.lock="fullscreenLoading"
            >退出登录</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="500px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="ruleForm"
      >
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="ruleForm.mobile" disabled></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input
            v-model="ruleForm.code"
            placeholder="请输入验证码"
            autocomplete="off"
          >
            <el-button
              slot="append"
              type="text"
              @click="getCode"
              class="codeStyle"
              :class="{ isColor: !disabled }"
              :disabled="disabled"
              >{{ valTxt }}</el-button
            >
          </el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input v-model="ruleForm.password" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="loading" @click="submitForm()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserInfo, loginOut, sendCode, passwordUpdate } from '@/request/api'
import { mapState, mapMutations } from 'vuex'
import { clearStorage } from '@/assets/js/utils'
import cookies from 'vue-cookies'
import md5 from 'js-md5'

export default {
  data() {
    return {
      userName: '',
      //   breadList: [],
      fullscreenLoading: false,
      loading: false,
      valTxt: '获取验证码',
      disabled: false,
      dialogFormVisible: false,
      circleUrl:
        'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      ruleForm: {
        mobile: cookies.get('mobile'),
        code: '',
        password: '',
        loginPwd: '',
      },
      rules: {
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    $route(to) {
      //   console.log(to, from, "route");
      this.setBreadList([to])
    },
  },
  computed: {
    ...mapState(['breadList']),
  },
  created() {
    this.getInfo()
    this.getBreadcrumb()
  },
  methods: {
    ...mapMutations(['setBreadList']),
    getInfo() {
      getUserInfo({}).then((res) => {
        if (res.status == 200) {
          this.userName = res.data.loginName
        }
      })
    },
    getBreadcrumb() {
      let matched = this.$route.matched
      if (matched.length > 1 && matched[1].name === '首页') {
        this.setBreadList([])
      } else {
        this.setBreadList(matched.slice(1))
      }
    },
    handleCommand(type) {
      if (type === 'loginOut') {
        this.fullscreenLoading = true
        loginOut({}).then((res) => {
          this.fullscreenLoading = false
          if (res.status == 200) {
            // this.$message({
            //   message: "退出成功！",
            //   type: "success",
            //   center: "true"
            // });
            clearStorage()
            this.$router.push({ path: '/' })
          }
        })
      } else {
        this.dialogFormVisible = true
      }
    },
    getCode() {
      sendCode({ mobile: this.ruleForm.mobile, type: 'MANAGE_UPDATE_PWD_SMS' })
        .then((res) => {
          if (res.status == 200) {
            if (!res.data.success) {
              this.$message({
                message: res.data.failMsg,
                type: 'error',
                center: 'true',
              })
            } else {
              this.$message({
                message: '验证码发送成功！',
                type: 'success',
                center: 'true',
              })
              this.tackBtn()
            }
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              center: 'true',
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60
      this.disabled = true
      this.valTxt = time + '秒后重试'
      time--
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer)
          this.valTxt = '获取验证码'
          this.disabled = false
        } else {
          this.disabled = true
          this.valTxt = time + '秒后重试'
          time--
        }
      }, 1000)
    },
    submitForm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.ruleForm.loginPwd = md5(this.ruleForm.password)
          passwordUpdate(this.ruleForm).then((res) => {
            this.loading = false
            if (res.status == 200) {
              this.$message({
                message: '设置成功',
                type: 'success',
                center: 'true',
              })

              setTimeout(() => {
                loginOut({}).then((res) => {
                  this.fullscreenLoading = false
                  if (res.status == 200) {
                    clearStorage()
                    this.$router.push({ path: '/' })
                  }
                })
              }, 1000)
            } else {
              this.$message({
                message: res.message,
                type: 'error',
                center: 'true',
              })
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.navTop {
    height: 64px;
    line-height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.el-breadcrumb {
    height: 64px;
    line-height: 64px;
}

.navTop-right {
    cursor: pointer;
}

.el-avatar--medium {
    vertical-align: middle;
}

.userTxt {
    margin-left: 10px;
    margin-right: 5px;
}
</style>
